import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import customersServices from "./customersServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toastMessage } from "../../../utils/toastMessage";
import { decrypt, secretUser } from "../../Store";
const initialState = {
  customers: [],
  selectedCustomersId: { label: "", key: "" },
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

//Get Customers
export const getCustomers = createAsyncThunk(
  "customers/getCustomers",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        agency_id: data,
      };
      return await customersServices.getCustomers(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Add Customers
export const addCustomers = createAsyncThunk(
  "customers/addCustomers",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await customersServices.addCustomers(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    reset: (state) => {
      state.customers = [];
      state.selectedCustomersId = { label: "", key: "" };
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Get Customers
      .addCase(getCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customers = action.payload.customers;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //Add Customers
      .addCase(addCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.selectedCustomersId = {
          label: action.payload?.social_reason,
          key: action.payload?.id,
        };
        if (action.payload?.id != null)
          toastMessage("success", "Client bien ajouter");
      })
      .addCase(addCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toastMessage("error", "Client Déja exist !");
      });
  },
});

export const { reset } = customersSlice.actions;
export default customersSlice.reducer;

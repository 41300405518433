import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import demandesServices from "./demandesServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toastMessage } from "../../../utils/toastMessage";
import { decrypt, secretUser } from "../../Store";
let statut_demande = "pending";
let offset = 0;

const initialState = {
  demandeCustomer: [],
  isDemandesCustomerLoading: false,
  pending: [],
  validated: [],
  canceled: [],
  past: [],
  actionType: "",
  actionAddDelete: "",
  demandeCandidates: [],
  isLoading: false,
  isDSCLoading: false,
  isPendingLoading: false,
  isValidatedLoading: false,
  isCanceledLoading: false,
  isPastLoading: false,
  isDemandeCandidateLoading: false,
  isDCLoading: false,
  isError: false,
  isAddError: false,
  isSuccess: false,
  isUpdateSuccess: false,
  isAddSuccess: false,
  isCancelSuccess: false,
  isAddDemandeSuccess: false,
  message: "",
};

//Get Demandes
export const getDemandes = createAsyncThunk(
  "demandes/getDemandes",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      statut_demande = data.statut_demande;
      offset = data.offset;

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        offset: data.offset,
        statut_demande: data.statut_demande,
        search: data.search,
        agency_id: data.agency_id,
        customer_id: data.customer_id,
        profession: data.profession,
        documents: data.documents,
        start_date: data.start_date,
        created_at: data.created_at,
      };
      return await demandesServices.getDemandes(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get demandes customer
export const getDemandesCustomer = createAsyncThunk(
  "demandes/getDemandesCustomer",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        offset: 0,
      };
      return await demandesServices.getDemandesCustomer(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Add Demandes
export const addDemandes = createAsyncThunk(
  "demandes/addDemandes",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };

      return await demandesServices.addDemandes(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Cancel Demandes
export const cancelDemandes = createAsyncThunk(
  "demandes/cancelDemandes",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
        comment: "",
      };

      return await demandesServices.cancelDemandes(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Update Demandes
export const updateDemandes = createAsyncThunk(
  "demandes/updateDemandes",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await demandesServices.updateDemandes(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Servir demande
export const servirDemandes = createAsyncThunk(
  "demandes/servirDemandes",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        demande_id: data,
      };
      return await demandesServices.servirDemandes(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Append candidate to demande
export const appendCandidate = createAsyncThunk(
  "demandes/appendCandidate",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await demandesServices.appendCandidate(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Delete candidate to demande
export const deleteCandidate = createAsyncThunk(
  "demandes/deleteCandidate",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await demandesServices.deleteCandidate(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get demande candidate;
export const getDemandeCandidate = createAsyncThunk(
  "demandes/getDemandeCandidate",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        demande_id: data,
      };
      return await demandesServices.getDemandeCandidate(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Search candidats by demande
export const searchCandidateDemande = createAsyncThunk(
  "demandes/searchCandidateDemande",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await demandesServices.searchCandidateDemande(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const demandesSlice = createSlice({
  name: "demandes",
  initialState,
  reducers: {
    reset: (state) => {
      state.demandeCustomer = [];
      state.pending = [];
      state.validated = [];
      state.canceled = [];
      state.past = [];
      state.actionType = "";
      state.demandeCandidates = [];
      state.searchCandidateResult = [];
      state.isDSCLoading = false;
      state.isLoading = false;
      state.isPendingLoading = false;
      state.isValidatedLoading = false;
      state.isCanceledLoading = false;
      state.isPastLoading = false;
      state.isDCLoading = false;
      state.isError = false;
      state.isAddError = false;
      state.isSuccess = false;
      state.isUpdateSuccess = false;
      state.isAddSuccess = false;
      state.isCancelSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Get Demandes
      .addCase(getDemandes.pending, (state) => {
        if (statut_demande == "pending") state.isPendingLoading = true;
        else if (statut_demande == "validated") state.isValidatedLoading = true;
        else if (statut_demande == "canceled") state.isCanceledLoading = true;
        else state.isPastLoading = true;
      })
      .addCase(getDemandes.fulfilled, (state, action) => {
        if (statut_demande == "pending") {
          state.pending =
            offset == 0
              ? action.payload
              : [...state.pending, ...action.payload];
          state.isPendingLoading = false;
        } else if (statut_demande == "validated") {
          state.validated =
            offset == 0
              ? action.payload
              : [...state.validated, ...action.payload];
          state.isValidatedLoading = false;
        } else if (statut_demande == "canceled") {
          state.canceled =
            offset == 0
              ? action.payload
              : [...state.canceled, ...action.payload];
          state.isCanceledLoading = false;
        } else {
          state.past =
            offset == 0 ? action.payload : [...state.past, ...action.payload];
          state.isPastLoading = false;
        }
      })
      .addCase(getDemandes.rejected, (state, action) => {
        if (statut_demande == "pending") state.isPendingLoading = true;
        else if (statut_demande == "validated") state.isValidatedLoading = true;
        else if (statut_demande == "canceled") state.isCanceledLoading = true;
        else state.isPastLoading = true;
        state.isError = true;
        state.message = action.payload;
      })
      //Get demandes customer
      .addCase(getDemandesCustomer.pending, (state) => {
        state.isDemandesCustomerLoading = true;
      })
      .addCase(getDemandesCustomer.fulfilled, (state, action) => {
        state.isDemandesCustomerLoading = false;
        state.demandeCustomer = action.payload.demandes;
      })
      .addCase(getDemandesCustomer.rejected, (state) => {
        state.isDemandesCustomerLoading = false;
      })
      //Add Demandes
      .addCase(addDemandes.pending, (state) => {
        state.isAddDemandeSuccess = false;
      })
      .addCase(addDemandes.fulfilled, (state) => {
        state.actionType = Math.random();
        state.isAddDemandeSuccess = true;
        // toastMessage("success", "Demande bien ajouter");
      })
      .addCase(addDemandes.rejected, (state, action) => {
        state.isAddDemandeSuccess = false;
        toastMessage("error", "Error ajouter");
      })
      //Cancel Demandes
      .addCase(cancelDemandes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(cancelDemandes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCancelSuccess = true;
        state.actionType = Math.random();
        toastMessage("success", "Demande bien annuler");
      })
      .addCase(cancelDemandes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isCancelSuccess = false;
        state.message = action.payload;
      })
      //Update Demandes
      .addCase(updateDemandes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateDemandes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateSuccess = true;
        state.actionType = Math.random();
        toastMessage("success", "Demande bien modifie");
      })
      .addCase(updateDemandes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isUpdateSuccess = false;
        state.message = action.payload;
        toastMessage(
          "error",
          "Supprimer tous les candidates lie à ce demande pour peuvent le modifier"
        );
      })
      //Servir demandes
      .addCase(servirDemandes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(servirDemandes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.actionType = Math.random();
      })
      .addCase(servirDemandes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //Append candidate to demande
      .addCase(appendCandidate.pending, (state) => {})
      .addCase(appendCandidate.fulfilled, (state, action) => {
        state.actionAddDelete = Math.random();
        toastMessage("success", "Candidats bien ajouter");
      })
      .addCase(appendCandidate.rejected, (state, action) => {
        toastMessage(
          "info",
          "Vous avez dépassé le nombre des candidats demandé !"
        );
      })
      //Delete candidate to demande
      .addCase(deleteCandidate.pending, () => {})
      .addCase(deleteCandidate.fulfilled, (state) => {
        state.actionAddDelete = Math.random();
      })
      .addCase(deleteCandidate.rejected, () => {})
      //Get demande candidate;
      .addCase(getDemandeCandidate.pending, (state) => {
        state.isDCLoading = true;
      })
      .addCase(getDemandeCandidate.fulfilled, (state, action) => {
        state.isDCLoading = false;
        state.demandeCandidates = action.payload;
      })
      .addCase(getDemandeCandidate.rejected, (state, action) => {
        state.isDCLoading = true;
      })
      //Search candidats by demande
      .addCase(searchCandidateDemande.pending, (state) => {
        state.isDSCLoading = true;
      })
      .addCase(searchCandidateDemande.fulfilled, (state, action) => {
        state.isDSCLoading = false;
        state.searchCandidateResult = action.payload?.candidates;
      })
      .addCase(searchCandidateDemande.rejected, (state, action) => {
        state.isDSCLoading = true;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = demandesSlice.actions;
export default demandesSlice.reducer;

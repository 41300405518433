import axios from "axios";
const API_URL = process.env.API_URL;

//Get Demandeurs
const getDemandeurs = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/recruiter/get-demandeurs`,
    body,
    config
  );
  return response.data;
};

//add Demandeurs
const addDemandeur = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/recruiter/create-demandeur`,
    body,
    config
  );
  return response.data;
};

//Get Customer Demandeurs
const getCustomerDemandeurs = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-demandeurs`,
    body,
    config
  );
  return response.data;
};

const demandeursServices = {
  getDemandeurs,
  addDemandeur,
  getCustomerDemandeurs,
};

export default demandeursServices;

import React, { useLayoutEffect, Suspense, lazy } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, memo, useState } from "react";
import {
  getCurrentUser,
  logoutToVerification,
} from "../redux/Features/auth/authSlice";
import Loader from "../components/Loader";
import { View } from "react-native";
const AppStack = lazy(() => import("./AppStack"));
const AppAuth = lazy(() => import("./AppAuth"));

const AppNavigation = memo(() => {
  const { user, isGetUserLoading, isGetUserSuccess, userExist } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  //Logout if there is  no activity
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer;
  const [goToVerfication, setGoToVerfication] = useState(null);
  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1000000);
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  const logoutAction = () => {
    dispatch(logoutToVerification());
    setGoToVerfication(true);
  };

  return (
    <Suspense fallback={<Loading />}>
      {/* <View
        style={{
          width: 320,
          flex: 1,
          marginHorizontal: "auto",
          borderWidth: 1.5,
          borderColor: "#ced4da",
          borderRadius: 10,
          overflow: "hidden",
        }}
      > */}
      <NavigationContainer>
        {user ? <AppStack /> : <AppAuth goToVerfication={goToVerfication} />}
      </NavigationContainer>
      {/* </View> */}
    </Suspense>
  );
});

export default AppNavigation;

const Loading = () => {
  return <Loader />;
};

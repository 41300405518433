import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import authServices from "./authServices";
import { decrypt, secretUser, encrypt } from "../../Store";

const initialState = {
  user: null,
  isLoading: false,
  isSenCodeLoading: false,
  isError: false,
  isSuccess: false,
  isGetUserLoading: false,
  isGetUserSuccess: false,
  userExist: false,
  message: "",
};

//Login
export const login = createAsyncThunk("auth/login", async (data, thunkAPI) => {
  try {
    const { navigation, phoneNumber } = data;
    return await authServices.login(navigation, phoneNumber);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return thunkAPI.rejectWithValue(message);
  }
});

//Verify sent code
export const verifySentCode = createAsyncThunk(
  "auth/verifySentCode",
  async (code, thunkAPI) => {
    try {
      return await authServices.verifySentCode(code);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Verify phone number
export const verifyPhoneNumber = createAsyncThunk(
  "auth/verifyPhoneNumber",
  async (_, thunkAPI) => {
    try {
      return await authServices.verifyPhoneNumber();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Logout
export const logout = createAsyncThunk("auth/logout", async (_, thunkAPI) => {
  try {
    return await authServices.logout();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

//Get Current User
export const getCurrentUser = createAsyncThunk(
  "auth/getCurrentUser",
  async (_, thunkAPI) => {
    let user = await AsyncStorage.getItem(secretUser);
    user = decrypt(user);
    return user.data.user;
  }
);

//logoutToVerification
export const logoutToVerification = createAsyncThunk(
  "auth/logoutToVerification",
  async (_, thunkAPI) => {
    try {
      return await authServices.logoutToVerification();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSenCodeLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isGetUserLoading = false;
      state.isGetUserSuccess = false;
      state.userExist = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Login
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      //verify sent code
      .addCase(verifySentCode.pending, (state) => {
        state.isSenCodeLoading = true;
      })
      .addCase(verifySentCode.fulfilled, (state, action) => {
        state.isSenCodeLoading = false;
        state.isSuccess = true;
        state.user = action.payload.user;
      })
      .addCase(verifySentCode.rejected, (state, action) => {
        state.isSenCodeLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Get Current User
      .addCase(getCurrentUser.pending, (state, action) => {
        state.isGetUserLoading = true;
        state.isGetUserSuccess = false;
        state.userExist = false;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isGetUserSuccess = true;
        state.isGetUserLoading = false;
        state.user = action.payload;
        state.userExist = true;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isGetUserLoading = false;
        state.isGetUserSuccess = false;
        // state.userExist = false;
      })

      // Logout user
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      })
      // Logout To Verification
      .addCase(logoutToVerification.fulfilled, (state) => {
        state.user = null;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;

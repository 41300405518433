import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import missionServices from "./missionServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toastMessage } from "../../../utils/toastMessage";
import { decrypt, secretUser } from "../../Store";

const initialState = {
  isProlongeLoading: false,
  isArrestLoading: false,
  isRertingLoading: false,
  isRertingSuccess: false,

  isProposeMissionLoading: false,
  isProposeMissionSuccess: false,
};

//Prolongation mission
export const prolongationMission = createAsyncThunk(
  "mission/prolongationMission",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const { candidate_id, date } = data;

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        candidate_id: candidate_id,
        date: date,
      };
      return await missionServices.prolongationMission(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Arrest mission
export const arrestMission = createAsyncThunk(
  "mission/arrestMission",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const { candidate_id, date } = data;

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        candidate_id: candidate_id,
        date: date,
      };
      return await missionServices.arrestMission(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Reporting an mission
export const reporteMission = createAsyncThunk(
  "mission/reporteMission",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const { candidate_id, type, comment, documents } = data;

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        candidate_id: candidate_id,
        type: type,
        comment: comment,
        documents: documents,
      };

      return await missionServices.reporteMission(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Propose mission
export const proposeMission = createAsyncThunk(
  "mission/proposeMission",
  async (candidate_id, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);

      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        candidate_id: candidate_id,
      };

      return await missionServices.proposeMission(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const missionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {
    resetPropostion: (state) => {
      state.isProposeMissionSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //Prolongation mission
      .addCase(prolongationMission.pending, (state) => {
        state.isProlongeLoading = true;
      })
      .addCase(prolongationMission.fulfilled, (state) => {
        state.isProlongeLoading = false;
        toastMessage("success", "La mission a été bien prolonger");
      })
      .addCase(prolongationMission.rejected, (state) => {
        state.isProlongeLoading = false;
      }) //Arrest mission
      .addCase(arrestMission.pending, (state) => {
        state.isArrestLoading = true;
      })
      .addCase(arrestMission.fulfilled, (state) => {
        state.isArrestLoading = false;
        toastMessage("success", "La mission a été bien arrest");
      })
      .addCase(arrestMission.rejected, (state) => {
        state.isArrestLoading = false;
      }) //reporting an mission
      .addCase(reporteMission.pending, (state) => {
        state.isRertingLoading = true;
        state.isRertingSuccess = false;
      })
      .addCase(reporteMission.fulfilled, (state) => {
        state.isRertingLoading = false;
        state.isRertingSuccess = true;
        toastMessage("success", "L'incident a été bien reporte");
      })
      .addCase(reporteMission.rejected, (state) => {
        state.isRertingLoading = false;
        state.isRertingSuccess = false;
      }) //Propose mission
      .addCase(proposeMission.pending, (state) => {
        state.isProposeMissionLoading = true;
        state.isProposeMissionSuccess = false;
      })
      .addCase(proposeMission.fulfilled, (state) => {
        state.isProposeMissionLoading = false;
        state.isProposeMissionSuccess = true;
      })
      .addCase(proposeMission.rejected, (state) => {
        state.isProposeMissionLoading = false;
        state.isProposeMissionSuccess = false;
      });
  },
});

export const { resetPropostion } = missionSlice.actions;
export default missionSlice.reducer;

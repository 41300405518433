import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import userServices from "./userServices";
import { decrypt, secretUser } from "../../Store";
import { toastMessage } from "../../../utils/toastMessage";
const initialState = {
  user: null,
  isUpdateImgLoading: false,
  isUpdateImgSuccess: false,
  isGetUserLoading: false,
  isGetUserSuccess: false,
  isUpdateUserSuccess: false,
};

//Get current user
export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
      };

      return await userServices.getCurrentUser(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Update photo
export const updatePhoto = createAsyncThunk(
  "user/updatePhoto",
  async (data, thunkAPI) => {
    try {
      const { extension, imageUrl } = data;

      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
        extension: extension.toString(),
        avatar: imageUrl,
      };

      return await userServices.updatePhoto(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Update profile information
export const updateProfile = createAsyncThunk(
  "user/updateProfile",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
        ...data,
      };

      return await userServices.updateProfile(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: (state) => {
      state.isUpdateImgLoading = false;
      state.isUpdateImgSuccess = false;
      state.isGetUserLoading = false;
      state.isGetUserSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //Update Photo
      .addCase(updatePhoto.pending, (state) => {
        state.isUpdateImgLoading = true;
      })
      .addCase(updatePhoto.fulfilled, (state) => {
        state.isUpdateImgLoading = false;
        state.isUpdateImgSuccess = true;
        toastMessage("success", "photo modifiée avec succès");
      })
      .addCase(updatePhoto.rejected, (state) => {
        state.isUpdateImgLoading = false;
        state.isUpdateImgSuccess = false;
      }) //Get current user
      .addCase(getCurrentUser.pending, (state) => {
        state.isGetUserLoading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.isGetUserLoading = false;
        state.isGetUserSuccess = true;
        state.user = action.payload;
      })
      .addCase(getCurrentUser.rejected, (state) => {
        state.isGetUserLoading = false;
        state.isGetUserSuccess = false;
      }) //Update profile information
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isUpdateUserSuccess = true;
        toastMessage("success", "Le profile est bien modifie");
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isUpdateUserSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = userSlice.actions;
export default userSlice.reducer;

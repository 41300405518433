import axios from "axios";

const API_URL = process.env.API_URL;

//Send Message By E-mail
const sendEmail = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/receive-profiles-by-email`,
    body,
    config
  );

  return response.data;
};
//Send Message By WhatsApp
const sendWhatsApp = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("body", body);
  const response = await axios.post(
    `${API_URL}/customers/receive-profiles-by-whatsapp`,
    body,
    config
  );

  return response.data;
};
//Receive Profiles By Consultant
const receiveProfiles = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/receive-profiles-by-consultant`,
    body,
    config
  );

  return response.data;
};

const receiveProfilesServices = {
  sendEmail,
  sendWhatsApp,
  receiveProfiles,
};

export default receiveProfilesServices;

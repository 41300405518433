import axios from "axios";

const API_URL = process.env.API_URL;

//Get Agencies
const getAgencies = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/get-agencies`,
    body,
    config
  );
  return response.data;
};

const agenciesServices = { getAgencies };

export default agenciesServices;

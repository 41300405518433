import { configureStore } from "@reduxjs/toolkit";
import candidatesReducer from "./Features/candidats/candidatesSlice";
import agenciesReducer from "./Features/agencies/agenciesSlice";
import ProfessionReducer from "./Features/professions/professionSlice";
import demandesReducer from "./Features/demandes/demandesSlice";
import documentsReducer from "./Features/documents/documentsSlice";
import customersReducer from "./Features/customers/customersSlice";
import demandeursReducer from "./Features/demandeurs/demandeursSlice";
import authReducer from "./Features/auth/authSlice";
import userReducer from "./Features/user/userSlice";
import missionReducer from "./Features/mission/missionSlice";
import favoriteReducer from "./Features/favorites/favoritesSlice";
import receiveProfilesReducer from "./Features/receiveProfiles/receiveProfilesSlice";
import CryptoJS from "crypto-js";

export const Store = configureStore({
  reducer: {
    auth: authReducer,
    candidates: candidatesReducer,
    professions: ProfessionReducer,
    demandes: demandesReducer,
    documents: documentsReducer,
    customers: customersReducer,
    agencies: agenciesReducer,
    demandeurs: demandeursReducer,
    user: userReducer,
    mission: missionReducer,
    favorite: favoriteReducer,
    receiveProfiles: receiveProfilesReducer,
  },
});
const secretKey = process.env.SECRET_KEY;
export const secretUser = process.env.SECRET_USER;
export const encrypt = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};
export const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

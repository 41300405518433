import axios from "axios";

const API_URL = process.env.API_URL;

//Get Demandes
const getDemandes = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/get-demandes-list`,
    body,
    config
  );
  return response.data;
};

//Get Demandes customer
const getDemandesCustomer = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-my-demandes`,
    body,
    config
  );
  return response.data;
};

//Add Demandes
const addDemandes = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/agents/create-demande`,
    body,
    config
  );
  return response.data;
};

//Cancel Demandes
const cancelDemandes = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/cancel-demande`,
    body,
    config
  );
  return response.data;
};

//Update Demandes
const updateDemandes = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/agents/update-demande`,
    body,
    config
  );
  return response.data;
};
//Servir demande
const servirDemandes = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/validated-demande`,
    body,
    config
  );
  return response.data;
};
//Append candidate to demande
const appendCandidate = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/append-candidat-to-demande`,
    body,
    config
  );
  return response.data;
};
//Delete candidate to demande
const deleteCandidate = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/delete-candidat-from-demande`,
    body,
    config
  );
  return response.data;
};
//Get demande candidate (validated)
const getDemandeCandidate = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/agents/get-demande-candidats`,
    body,
    config
  );

  return response.data;
};
//Search candidats by demande
const searchCandidateDemande = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/candidate/search-candidats-by-demande`,
    body,
    config
  );
  return response.data;
};
const demandesServices = {
  getDemandes,
  getDemandesCustomer,
  addDemandes,
  cancelDemandes,
  updateDemandes,
  servirDemandes,
  appendCandidate,
  deleteCandidate,
  getDemandeCandidate,
  searchCandidateDemande,
};

export default demandesServices;

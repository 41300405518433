import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import receiveProfilesServices from "./receiveProfilesServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toastMessage } from "../../../utils/toastMessage";
import { decrypt, secretUser } from "../../Store";

const initialState = {
  //receive profiles states
  isReceiveLoading: false,
  isReceiveSuccess: false,

  //send email states
  isSendEmailLoading: false,
  isSendEmailSuccess: false,

  //send whatsapp
  isSendWhatsAppLoading: false,
  isSendWhatsAppSuccess: false,
};

//Send Message By E-mail
export const sendEmail = createAsyncThunk(
  "receiveProfiles/sendEmail",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await receiveProfilesServices.sendEmail(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Send Message By WhatsApp
export const sendWhatsApp = createAsyncThunk(
  "receiveProfiles/sendWhatsApp",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        ...data,
      };
      return await receiveProfilesServices.sendWhatsApp(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Receive Profiles By Consultant
export const receiveProfiles = createAsyncThunk(
  "receiveProfiles/receiveProfiles",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
      };
      return await receiveProfilesServices.receiveProfiles(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const receiveProfilesSlice = createSlice({
  name: "receiveProfiles",
  initialState,
  reducers: {
    resetReceiveStates: (state) => {
      state.isReceiveLoading = false;
      state.isReceiveSuccess = false;
    },
    resetSendEmailStates: (state) => {
      state.isSendEmailLoading = false;
      state.isSendEmailSuccess = false;
    },
    resetSendWhatsAppStates: (state) => {
      state.isSendWhatsAppLoading = false;
      state.isSendWhatsAppSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //Send Message By E-mail
      .addCase(sendEmail.pending, (state) => {
        state.isSendEmailSuccess = false;
        state.isSendEmailLoading = true;
      })
      .addCase(sendEmail.fulfilled, (state) => {
        state.isSendEmailSuccess = true;
        state.isSendEmailLoading = false;
      })
      .addCase(sendEmail.rejected, (state) => {
        state.isSendEmailSuccess = false;
        state.isSendEmailLoading = false;
        toastMessage("error", "Envoyer email erreur!");
      })
      //Send Message By WhatsApp
      .addCase(sendWhatsApp.pending, (state) => {
        state.isSendWhatsAppLoading = true;
        state.isSendWhatsAppSuccess = false;
      })
      .addCase(sendWhatsApp.fulfilled, (state) => {
        state.isSendWhatsAppLoading = false;
        state.isSendWhatsAppSuccess = true;
      })
      .addCase(sendWhatsApp.rejected, (state, action) => {
        state.isSendWhatsAppLoading = false;
        state.isSendWhatsAppSuccess = false;
        toastMessage("error", "Envoyer Whatsapp erreur!");
        console.log("rejected", action.payload);
      })
      //Receive Profiles By Consultant
      .addCase(receiveProfiles.pending, (state) => {
        state.isReceiveLoading = true;
        state.isReceiveSuccess = false;
      })
      .addCase(receiveProfiles.fulfilled, (state) => {
        state.isReceiveLoading = false;
        state.isReceiveSuccess = true;
      })
      .addCase(receiveProfiles.rejected, (state) => {
        state.isReceiveLoading = false;
        state.isReceiveSuccess = false;
      });
  },
});

export const {
  resetReceiveStates,
  resetSendEmailStates,
  resetSendWhatsAppStates,
} = receiveProfilesSlice.actions;
export default receiveProfilesSlice.reducer;

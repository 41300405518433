import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import favoritesServices from "./favoritesServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { decrypt, secretUser } from "../../Store";

const initialState = {
  favoriteCandidates: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  refresh: 0,
  refreshHome: 0,
};

//Set candidate to my favotite
let screen = "";
export const setCandidateFavotite = createAsyncThunk(
  "favorite/setCandidateFavotite",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      screen = data?.screen;
      const body = {
        user_id: user_id,
        candidate_id: data?.candidate_id,
        action: data?.action,
      };
      return await favoritesServices.setCandidateFavotite(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Set candidate to my favotite
let offset = 0;
export const getCandidateFavotite = createAsyncThunk(
  "favorite/getCandidateFavotite",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      offset = data?.offset;
      const body = {
        user_id: user_id,
        ...data,
      };
      return await favoritesServices.getCandidateFavotite(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const favoritesSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    reset: (state) => {
      state.favoriteCandidates = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.refresh = 0;
      state.refreshHome = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      //Set candidate to my favotite
      .addCase(setCandidateFavotite.pending, (state) => {
        state.isError = false;
        state.isSuccess = false;
      })
      .addCase(setCandidateFavotite.fulfilled, (state) => {
        state.isSuccess = true;
        if (screen == "favorite") state.refreshHome = Math.random();
        state.refresh = Math.random();
      })
      .addCase(setCandidateFavotite.rejected, (state) => {
        state.isError = true;
      })
      //Get favotite candidates
      .addCase(getCandidateFavotite.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCandidateFavotite.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.favoriteCandidates =
          offset == 0
            ? action.payload?.candidates
            : [...state.favoriteCandidates, ...action.payload?.candidates];
      })
      .addCase(getCandidateFavotite.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const { reset } = favoritesSlice.actions;
export default favoritesSlice.reducer;

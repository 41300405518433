import Toast from "react-native-toast-message";

export const toastMessage = (type, text) => {
  Toast.show({
    type: type,
    text2: text,
    visibilityTime: 3000,
    onPress: () => {
      Toast.hide();
    },
  });
};

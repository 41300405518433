import axios from "axios";

const API_URL = process.env.API_URL;

//Prolongation mission
const prolongationMission = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/prolongation-mission`,
    body,
    config
  );
  return response.data;
};

//Arrest mission
const arrestMission = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/arrest-mission`,
    body,
    config
  );
  return response.data;
};

//reporting an  mission
const reporteMission = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/reporting-an-incident`,
    body,
    config
  );
  return response.data;
};

//Propose mission
const proposeMission = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/proposed-mission-to-candidate`,
    body,
    config
  );

  return response.data;
};

const missionServices = {
  prolongationMission,
  arrestMission,
  reporteMission,
  proposeMission,
};

export default missionServices;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import demandeursServices from "./demandeursServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toastMessage } from "../../../utils/toastMessage";
import { decrypt, secretUser } from "../../Store";
let addType = "demandeur";

const initialState = {
  demandeurs: [],
  customerDemandeurs: [],
  selectedDemandeur: { label: "", key: "" },
  selectedContact: { label: "", key: "" },
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

//Get Demandeurs
export const getDemandeurs = createAsyncThunk(
  "demandeurs/getDemandeurs",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        customer_id: data,
      };
      return await demandeursServices.getDemandeurs(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Add Demandeurs
export const addDemandeur = createAsyncThunk(
  "demandeurs/addDemandeur",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      addType = data.addType;
      const body = {
        user_id: user_id,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        civilite: data.civilite,
        mobile: data.mobile,
        customer_id: data.customer_id,
        agency_id: data.agency_id,
      };
      return await demandeursServices.addDemandeur(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get Customer Demandeurs
export const getCustomerDemandeurs = createAsyncThunk(
  "demandeurs/getCustomerDemandeurs",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
      };
      return await demandeursServices.getCustomerDemandeurs(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const demandeursSlice = createSlice({
  name: "demandeurs",
  initialState,
  reducers: {
    reset: (state) => {
      state.demandeurs = [];
      state.customerDemandeurs = [];
      state.selectedDemandeur = { label: "", key: "" };
      state.selectedContact = { label: "", key: "" };
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Get Demandeurs
      .addCase(getDemandeurs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDemandeurs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.demandeurs = action.payload;
      })
      .addCase(getDemandeurs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = "";
      })
      //Add Demandeurs
      .addCase(addDemandeur.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDemandeur.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;

        if (addType == "demandeur") {
          state.selectedDemandeur = {
            label: action.payload?.first_name + " " + action.payload?.last_name,
            key: action.payload?.id,
          };
          toastMessage("success", "Demandeur bien ajouter");
        } else {
          state.selectedContact = {
            label: action.payload?.first_name + " " + action.payload?.last_name,
            key: action.payload?.id,
          };
          toastMessage("success", "Contact bien ajouter");
        }
      })
      .addCase(addDemandeur.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toastMessage("info", "Demandeur already exist");
      }) //Get Customer Demandeurs
      .addCase(getCustomerDemandeurs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCustomerDemandeurs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.customerDemandeurs = action.payload;
      })
      .addCase(getCustomerDemandeurs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = demandeursSlice.actions;
export default demandeursSlice.reducer;

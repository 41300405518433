import axios from "axios";

const API_URL = process.env.API_URL;

//Set candidate to my favotite
const setCandidateFavotite = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/set-candidate-to-my-favotite`,
    body,
    config
  );
  return response.data;
};

//Get favotite candidates
const getCandidateFavotite = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-my-favotites`,
    body,
    config
  );
  return response.data;
};
const favoritesServices = {
  setCandidateFavotite,
  getCandidateFavotite,
};

export default favoritesServices;

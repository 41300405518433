import { View, Image } from "react-native";
import React from "react";
import { loading } from "../assets";
import tw from "twrnc";

const Loader = () => {
  return (
    <View style={[tw`bg-[#fff] w-full h-full items-center justify-center`]}>
      <Image source={loading} style={{ width: 70, height: 70 }} />
    </View>
  );
};

export default Loader;

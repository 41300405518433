import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import documentsServices from "./documentsServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { decrypt, secretUser } from "../../Store";

const initialState = {
  documents: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

//Get Documents
export const getDocuments = createAsyncThunk(
  "documents/getDocuments",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
      };

      return await documentsServices.getDocuments(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    reset: (state) => {
      state.documents = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Get Documents
      .addCase(getDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.documents = action.payload.documnet_types;
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = "";
      });
  },
});

export const { reset } = documentsSlice.actions;
export default documentsSlice.reducer;

import axios from "axios";

const API_URL = process.env.API_URL;

//Get current user
const getCurrentUser = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/users/get-user-profile-info`,
    body,
    config
  );

  return response.data;
};

//Update user photo
const updatePhoto = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/users/update-avatar`,
    body,
    config
  );
  return response.data;
};

//Update profile information
const updateProfile = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/users/update-profile`,
    body,
    config
  );
  return response.data;
};

const authServices = {
  updatePhoto,
  getCurrentUser,
  updateProfile,
};

export default authServices;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import candidatesServices from "./candidatesServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { decrypt, secretUser } from "../../Store";
import { toastMessage } from "../../../utils/toastMessage";
const initialState = {
  candidates: [],
  candidatesDisponible: [],
  candidatesNotDisponible: [],
  candidate: {},
  clientCandidates: [],
  clientVipCandidates: [],
  clientAllCandidates: [],
  similairesCandidates: [],
  candidatesCompletedMission: [],
  motifs: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  isGetMotifSuccess: false,

  isCandidatsDispoOrNotLoading: false,
  message: "",

  //Loading
  isStatusLoading: false,
  isCandidatLoading: false,
  isCandidatsLoading: false,
  isCandidatsCompletedMissionLoading: false,
  isCandidatsEnCourLoading: false,

  //Is disponible states
  isDisponibleLoading: false,
  isDisponibleSuccess: false,
};

//Get candidates
let offsetCandidates = 1;
export const getCandidates = createAsyncThunk(
  "candidates/getCandidates",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      offsetCandidates = data.offset;

      const body = {
        ...data,
        user_id: user_id,
      };
      return await candidatesServices.getCandidates(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get candidates
let motif = "disponible";
let offset = 1;
export const GetCandidatsDispoOrNotDispo = createAsyncThunk(
  "candidates/GetCandidatsDispoOrNotDispo",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      motif = data?.motif;
      offset = data?.offset;

      const body = {
        user_id: user_id,
        ...data,
      };

      return await candidatesServices.GetCandidatsDispoOrNotDispo(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get candidate
export const getCandidate = createAsyncThunk(
  "candidates/getCandidate",
  async (id, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
        candidate_id: id,
      };

      return await candidatesServices.getCandidate(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Change candidats status
export const changeCandidatStatus = createAsyncThunk(
  "candidates/changeCandidatStatus",
  async (data, thunkAPI) => {
    try {
      const { candidate_id, motif_id, start_date, end_date, comment } = data;

      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
        candidate_id: candidate_id,
        motif_id: motif_id,
        start_date: start_date,
        end_date: "",
        comment: comment,
      };

      return await candidatesServices.changeCandidatStatus(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get motif
export const getMotif = createAsyncThunk(
  "candidates/getMotif",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
      };

      return await candidatesServices.getMotif(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Customer API's -----------------------------------
//Get candidat for client
export const getClientCandidates = createAsyncThunk(
  "candidates/getClientCandidates",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = { user_id, offset: 1 };

      return await candidatesServices.getClientCandidates(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get vip candidat for client
export const getClientVipCandidates = createAsyncThunk(
  "candidates/getClientVipCandidates",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = { user_id, offset: 1 };

      return await candidatesServices.getClientVipCandidates(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get all candidat for client
export const getAllClientCandidates = createAsyncThunk(
  "candidates/getAllClientCandidates",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = { user_id, offset: 1 };

      return await candidatesServices.getAllClientCandidates(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get similaires candidates
export const getSimilairesCandidates = createAsyncThunk(
  "candidates/getSimilairesCandidates",
  async (_, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];

      const body = {
        user_id: user_id,
        offset: 1,
      };

      return await candidatesServices.getSimilairesCandidates(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Get candidates completed mission
let offsetMission = 1;
export const getcandidatesCompletedMission = createAsyncThunk(
  "candidates/getcandidatesCompletedMission",
  async (offset, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      offsetMission = offset;
      const body = { user_id, offset: offset };

      return await candidatesServices.getcandidatesCompletedMission(
        body,
        token
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Check Candidate Is Disponible
export const checkCandidateDisponible = createAsyncThunk(
  "candidates/checkCandidateDisponible",
  async (candidate_id, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = { user_id, candidate_id: candidate_id };

      return await candidatesServices.checkCandidateDisponible(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const candidatesSlice = createSlice({
  name: "candidates",
  initialState,
  reducers: {
    reset: (state) => {
      state.candidates = [];
      state.candidatesDisponible = [];
      state.candidatesNotDisponible = [];
      state.candidate = {};
      state.clientCandidates = [];
      state.clientVipCandidates = [];
      state.clientAllCandidates = [];
      state.similairesCandidates = [];
      state.candidatesCompletedMission = [];
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.isCandidatLoading = false;
      state.isCandidatsLoading = false;
      state.message = "";
    },

    resetIsDisponible: (state) => {
      state.isDisponibleSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //Get candidats
      .addCase(getCandidates.pending, (state) => {
        state.isCandidatsLoading = true;
      })
      .addCase(getCandidates.fulfilled, (state, action) => {
        state.isCandidatsLoading = false;
        let data = [];
        for (let i = 0; i < action.payload?.length / 5; i++) {
          data.push({
            oneWorker: action.payload.slice(5 * i, 5 * i + 1)[0],
            data: action.payload.slice(i * 5 + 1, i * 5 + 5),
          });
        }
        if (offsetCandidates == 1) {
          state.candidates = data;
        } else state.candidates = state.candidates.concat(data);
      })
      .addCase(getCandidates.rejected, (state, action) => {
        state.isCandidatsLoading = true;
        state.isError = true;
        state.message = action.payload;
      })
      //Get motif
      .addCase(getMotif.pending, (state) => {
        state.isGetMotifSuccess = false;
      })
      .addCase(getMotif.fulfilled, (state, action) => {
        state.isGetMotifSuccess = true;
        state.motifs = action.payload.motifs;
      })
      .addCase(getMotif.rejected, (state, action) => {
        state.isGetMotifSuccess = false;
      })
      //Get candidats dispo or not dispo
      .addCase(GetCandidatsDispoOrNotDispo.pending, (state) => {
        state.isCandidatsDispoOrNotLoading = true;
      })
      .addCase(GetCandidatsDispoOrNotDispo.fulfilled, (state, action) => {
        state.isCandidatsDispoOrNotLoading = false;
        if (motif == "disponible")
          state.candidatesDisponible =
            offset == 1
              ? action.payload
              : [...state.candidatesDisponible, ...action.payload];
        else
          state.candidatesNotDisponible =
            offset == 1
              ? action.payload
              : [...state.candidatesNotDisponible, ...action.payload];
      })
      .addCase(GetCandidatsDispoOrNotDispo.rejected, (state, action) => {
        state.isCandidatsDispoOrNotLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //Get candidat
      .addCase(getCandidate.pending, (state) => {
        state.isCandidatLoading = true;
      })
      .addCase(getCandidate.fulfilled, (state, action) => {
        state.isCandidatLoading = false;
        state.candidate = action.payload;
      })
      .addCase(getCandidate.rejected, (state, action) => {
        state.isCandidatLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //Change Candidat Status
      .addCase(changeCandidatStatus.pending, (state) => {
        state.isStatusLoading = true;
      })
      .addCase(changeCandidatStatus.fulfilled, (state, action) => {
        state.isStatusLoading = false;
        toastMessage("success", "le statut a été changé avec succès");
      })
      .addCase(changeCandidatStatus.rejected, (state, action) => {
        state.isStatusLoading = false;
      })
      // Client candidats
      .addCase(getClientCandidates.pending, (state) => {
        state.isCandidatsEnCourLoading = true;
      })
      .addCase(getClientCandidates.fulfilled, (state, action) => {
        state.isCandidatsEnCourLoading = false;
        state.isSuccess = true;
        state.clientCandidates = action.payload;
      })
      .addCase(getClientCandidates.rejected, (state, action) => {
        state.isCandidatsEnCourLoading = false;
        state.isError = true;
        state.message = action.payload;
      }) // Client vip candidat
      .addCase(getClientVipCandidates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClientVipCandidates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientVipCandidates = action.payload;
      })
      .addCase(getClientVipCandidates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      }) // Client all candidat
      .addCase(getAllClientCandidates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllClientCandidates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.clientAllCandidates = action.payload;
      })
      .addCase(getAllClientCandidates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      }) // Similaires Candidates
      .addCase(getSimilairesCandidates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSimilairesCandidates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.similairesCandidates = action.payload;
      })
      .addCase(getSimilairesCandidates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      // Candidates completed mission
      .addCase(getcandidatesCompletedMission.pending, (state) => {
        state.isCandidatsCompletedMissionLoading = true;
      })
      .addCase(getcandidatesCompletedMission.fulfilled, (state, action) => {
        state.isCandidatsCompletedMissionLoading = false;
        state.isSuccess = true;
        state.candidatesCompletedMission =
          offsetMission == 1
            ? action.payload?.candidates
            : [
                ...state.candidatesCompletedMission,
                ...action.payload?.candidates,
              ];
      })
      .addCase(getcandidatesCompletedMission.rejected, (state, action) => {
        state.isCandidatsCompletedMissionLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      //Check Candidate Is Disponible
      .addCase(checkCandidateDisponible.pending, (state) => {
        state.isDisponibleLoading = true;
        state.isDisponibleSuccess = false;
      })
      .addCase(checkCandidateDisponible.fulfilled, (state) => {
        state.isDisponibleLoading = false;
        state.isDisponibleSuccess = true;
      })
      .addCase(checkCandidateDisponible.rejected, (state) => {
        state.isDisponibleLoading = false;
        state.isDisponibleSuccess = false;
      });
  },
});

export const { reset, resetIsDisponible } = candidatesSlice.actions;
export default candidatesSlice.reducer;

import axios from "axios";

const API_URL = process.env.API_URL;

//Get Documents
const getDocuments = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/get-document-types`,
    body,
    config
  );
  return response.data;
};

const documentsServices = { getDocuments };

export default documentsServices;

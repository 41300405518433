import axios from "axios";

const API_URL = process.env.API_URL;

//Get Professions
const getProfessions = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/get-professions-search-candidats`,
    {
      user_id: userId,
    },
    config
  );

  return response.data.professions;
};

const professionService = { getProfessions };

export default professionService;

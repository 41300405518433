import axios from "axios";

const API_URL = process.env.API_URL;

//Get Customers
const getCustomers = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/get-customers`,
    body,
    config
  );
  return response.data;
};
//add Customers
const addCustomers = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/agents/create-customer`,
    body,
    config
  );
  return response.data;
};

const customersServices = {
  getCustomers,
  addCustomers,
};

export default customersServices;

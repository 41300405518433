//Logo
import vision_icon from "./vision-icon.png";
import vision_logo from "./vision-logo.png";
import ProfileDefault from "./ProfileDefault.jpg";

//Images
import ponto1 from "./ponto1.jpeg";
import ponto2 from "./ponto2.jpeg";
import ponto3 from "./ponto3.jpeg";
import ponto4 from "./ponto4.jpeg";
import siva from "./siva.jpeg";
import melanie from "./melanie.jpeg";
import tatiana from "./tatiana.jpeg";
import mapsImage from "./maps-image.jpg";
import visionAgence from "./vision-agence.png";
import worker1 from "./worker1.jpg";

//Illustrations
import help from "./help.png";
import noDataIcon from "./no-data.png";

//Icons
import close from "./close.png";
import topRated from "./top-rated.png";
import clock from "./clock.png";
import all from "./all.png";
import user from "./user.png";
import similaires from "./similaires.png";
import vip from "./vip.png";
import maps from "./maps.png";
import agencyIcon from "./agency-icon.png";
import heartEmpty from "./heart-empty.png";
import heartFull from "./heart-full.png";
import favorite from "./favorite.png";
import warning from "./warning.png";
import road from "./road.svg";
import email from "./email.png";
import megaphone from "./megaphone.png";
import medical from "./medical.png";
import certificate from "./certificate.png";
import upload from "./upload.png";
import userIcon from "./user-icon.png";
import logout from "./logout.png";
import notification from "./notification.png";
import pdf from "./pdf-file.png";
import file from "./file.png";
import menu from "./menu.png";
import filter from "./filter.png";
import driver from "./driver.png";
import tile from "./tile.png";
import leader from "./leader.png";
import cheif from "./cheif.png";
import tracking from "./tracking.png";
import centralisteIcon from "./centraliste-icon.png";
import waterPipe from "./water-pipe.png";
import pipeline from "./pipeline.png";
import forklift from "./forklift.png";
import truck from "./truck.png";
import documents from "./documents.png";
import chef_equipe from "./chef_equipe.png";
import chauffeur_vl from "./chauffeur_vl.png";
import chauffeur_tc from "./chauffeur_tc.png";
import centraliste from "./centraliste.png";
import chef_chantier from "./chef_chantier.png";
import Aide_canalisateur from "./Aide_canalisateur.png";
import canalisateur from "./canalisateur.png";
import carreleur from "./carreleur.png";
import chauffeur_PL from "./chauffeur_PL.png";
import cariste from "./cariste.png";
import constructionWorker from "./construction-worker.png";
import home from "./home.png";
import users from "./users.png";
import workers from "./workers.png";
import message from "./message.png";
import demandes from "./demandes.png";

//Animation icons
import loading from "./loading.gif";
import confirmation from "./confirmation.gif";
import firstuse from "./firstuse.gif";
import phonecall from "./phone-call.gif";
import sendemail from "./email.gif";
import whatstapp from "./whatstapp.gif";
import searchAnimation from "./search-animation.gif";

export {
  //Logo
  vision_icon,
  vision_logo,
  ProfileDefault,

  //Images
  siva,
  melanie,
  tatiana,
  ponto1,
  ponto2,
  ponto3,
  ponto4,
  worker1,
  mapsImage,
  visionAgence,

  //Illustrations
  help,
  noDataIcon,

  //Icons
  chef_equipe,
  chauffeur_vl,
  chauffeur_tc,
  centraliste,
  chef_chantier,
  Aide_canalisateur,
  canalisateur,
  carreleur,
  chauffeur_PL,
  cariste,
  maps,
  close,
  user,
  topRated,
  clock,
  vip,
  all,
  similaires,
  agencyIcon,
  heartEmpty,
  heartFull,
  favorite,
  warning,
  road,
  email,
  megaphone,
  medical,
  certificate,
  upload,
  constructionWorker,
  home,
  userIcon,
  logout,
  notification,
  users,
  workers,
  message,
  pdf,
  file,
  demandes,
  menu,
  filter,
  driver,
  tile,
  leader,
  cheif,
  tracking,
  centralisteIcon,
  waterPipe,
  pipeline,
  forklift,
  truck,
  documents,

  //Animation icons
  loading,
  confirmation,
  firstuse,
  phonecall,
  sendemail,
  whatstapp,
  searchAnimation,
};

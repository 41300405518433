import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agenciesServices from "./agenciesServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { decrypt, secretUser } from "../../Store";

const initialState = {
  agencies: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

//Get Agencies
export const getAgencies = createAsyncThunk(
  "agencies/getAgencies",
  async (data, thunkAPI) => {
    try {
      let user = await AsyncStorage.getItem(secretUser);
      user = decrypt(user);
      const user_id = user.data.user.id;
      const token = user["access_token"];
      const body = {
        user_id: user_id,
        agency_id: data,
      };
      return await agenciesServices.getAgencies(body, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const agenciesSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    reset: (state) => {
      (state.pending = []),
        (state.validated = []),
        (state.canceled = []),
        (state.past = []),
        (state.isLoading = false);
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      //Get Agencies
      .addCase(getAgencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAgencies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.agencies = action.payload.agencies;
      })
      .addCase(getAgencies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = "";
      });
  },
});

export const { reset } = agenciesSlice.actions;
export default agenciesSlice.reducer;

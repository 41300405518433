import axios from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { toastMessage } from "../../../utils/toastMessage";
import { encrypt, decrypt, secretUser } from "../../Store";
const API_URL = process.env.API_URL;

//Login user
const login = async (navigation, phoneNumber) => {
  try {
    const res = await axios.post(`${API_URL}/security/create-token`, {
      phone_number: phoneNumber.toString(),
    });

    await AsyncStorage.setItem(
      secretUser,
      encrypt({
        phone_number: phoneNumber,
        access_token: res.data.access_token,
      })
    );

    navigation.navigate("Verification", {
      name: "Verification",
    });
  } catch {
    toastMessage("error", "Numéro de téléphone invalide !");
  }
};

//Verify sent code
const verifySentCode = async (code) => {
  try {
    let user = await AsyncStorage.getItem(secretUser);
    user = decrypt(user);

    const res = await axios.post(
      `${API_URL}/security/verify-sent-code`,
      {
        code: code,
        phone_number: user["phone_number"],
      },
      {
        headers: {
          Authorization: "Bearer " + user["access_token"],
        },
      }
    );
    await AsyncStorage.setItem(
      secretUser,
      encrypt({
        access_token: user["access_token"],
        data: res.data,
      })
    );
    return res.data;
  } catch (error) {
    toastMessage("error", "Code de vérification invalide! 👋");
    throw error;
  }
};

// Verify phone number
const verifyPhoneNumber = async () => {
  let user = await AsyncStorage.getItem(secretUser);
  user = decrypt(user);
  const res = await axios.post(
    `${API_URL}/security/verify-phone-number`,
    { phone_number: user["phone_number"] },
    {
      headers: { Authorization: `Bearer ${user["access_token"]}` },
    }
  );

  //global code
  await AsyncStorage.setItem(
    secretUser,
    encrypt({
      phone_number: access_token["phone_number"],
      access_token: access_token["access_token"],
    })
  );
};

//Logout user
const logout = async () => {
  await AsyncStorage.removeItem(secretUser);
};
//Logout to verification
const logoutToVerification = async () => {
  let user = await AsyncStorage.getItem(secretUser);
  user = decrypt(user);
  await AsyncStorage.setItem(
    secretUser,
    encrypt({
      phone_number: user?.data?.user?.phone,
      access_token: user?.access_token,
    })
  );
};

const authServices = {
  verifySentCode,
  verifyPhoneNumber,
  login,
  logout,
  logoutToVerification,
};

export default authServices;

import { SafeAreaView, StyleSheet, StatusBar, Dimensions } from "react-native";
import React from "react";
import { Provider } from "react-redux";
import { Store } from "./app/redux/Store";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet"; //=>Problem ! loader
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import AppNavigator from "./app/navigation/AppNavigation";
import Toast from "react-native-toast-message";
const App = () => {
  const [fontsLoaded] = useFonts({
    "poppins-black": require("./app/assets/font/Poppins-Black.ttf"),
    "poppins-bold": require("./app/assets/font/Poppins-Bold.ttf"),
    "poppins-extraBold": require("./app/assets/font/Poppins-ExtraBold.ttf"),
    "poppins-medium": require("./app/assets/font/Poppins-Medium.ttf"),
    "poppins-light": require("./app/assets/font/Poppins-Light.ttf"),
    "poppins-extraLight": require("./app/assets/font/Poppins-ExtraLight.ttf"),
    "poppins-semibold": require("./app/assets/font/Poppins-SemiBold.ttf"),
    "poppins-regular": require("./app/assets/font/Poppins-Regular.ttf"),
    "poppins-thin": require("./app/assets/font/Poppins-Thin.ttf"),
  });

  if (!fontsLoaded) {
    return null;
  } else {
    SplashScreen.hideAsync();
  }

  return (
    <Provider store={Store}>
      <BottomSheetModalProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <SafeAreaView style={styles.container}>
            <AppNavigator />
          </SafeAreaView>
        </GestureHandlerRootView>
      </BottomSheetModalProvider>
      <Toast />
    </Provider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: StatusBar.currentHeight,
  },
  scrollView: {
    flex: 1,
    backgroundColor: "#ECF5FA",
  },
  backgroundImage: {
    width: Dimensions.get("screen").width,
    height: Dimensions.get("screen").height,
  },
});

export default App;

import axios from "axios";

const API_URL = process.env.API_URL;

//Get Candidats
const getCandidates = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/candidate/search-candidats`,
    body,
    config
  );
  return response.data;
};

//Get Candidat
const getCandidate = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/candidate/get-candidate-detail`,
    body,
    config
  );
  return response.data;
};

//Get candidat for client
const getClientCandidates = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-my-candidates`,
    body,
    config
  );
  return response.data;
};

//Get vip candidat for client
const getClientVipCandidates = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-vip-candidates`,
    body,
    config
  );

  return response.data;
};

//Get all candidat for client
const getAllClientCandidates = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-interims`,
    body,
    config
  );

  return response.data;
};

//Get Similaires Candidates for client
const getSimilairesCandidates = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${API_URL}/customers/get-candidates`,
    body,
    config
  );
  return response.data;
};

//Get candidat completed mission
const getcandidatesCompletedMission = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/get-candidates-completed-mission`,
    body,
    config
  );
  return response.data;
};

//Get candidats dispo or not dispo
const GetCandidatsDispoOrNotDispo = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/candidate/get-candidats-dispo-or-not-dispo`,
    body,
    config
  );
  return response.data;
};

//Change candidats status
const changeCandidatStatus = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/candidate/change-status`,
    body,
    config
  );
  return response.data;
};

//Get motif
const getMotif = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/candidate/get-motifs`,
    body,
    config
  );
  return response.data;
};

//Check Candidate Is Disponible
const checkCandidateDisponible = async (body, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.post(
    `${API_URL}/customers/check-candidate-is-disponible`,
    body,
    config
  );

  return response.data;
};

const candidatesServices = {
  getCandidates,
  getCandidate,
  getClientCandidates,
  getClientVipCandidates,
  getAllClientCandidates,
  getSimilairesCandidates,
  getcandidatesCompletedMission,
  GetCandidatsDispoOrNotDispo,
  changeCandidatStatus,
  getMotif,
  checkCandidateDisponible,
};

export default candidatesServices;
